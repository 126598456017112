import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavigationHeader from './layouts/Navbar'
import MobileFooterNavigation from './layouts/Footer'
import './App.css';
import Home from './pages/Home';
//import Login from './pages/Login';


//Try to load all the pages lazy if that is not readly required (but you will see a flickering during the first time)
const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));
const Orders = lazy(() => import('./pages/Orders'));
const Browse = lazy(() => import('./pages/Browse'));
const Community = lazy(() => import('./pages/Community'));
const Feeds = lazy(() => import('./pages/Feeds'));
const Recents = lazy(() => import('./pages/Recents'));
const Settings = lazy(() => import('./pages/Settings'));
const VendorDashboard = lazy(() => import('./pages/vendor/VendorDashboard'));
const VendorFacilities = lazy(() => import('./pages/vendor/facilities/VendorFacilities'));
const VendorModifyFacility = lazy(() => import('./pages/vendor/facilities/VendorModifyFacility'));
const Page404 = lazy(() => import('./pages/Page404'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
      <div className='flex flex-col justify-between' >
          <NavigationHeader />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/browse/:category" element={<Home />} />
              <Route exact path="/signup" element={<Register />} />
              <Route exact path="/signin" element={<Login />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/feeds" element={<Feeds />} />
              <Route path="/browse" element={<Browse />} />
              <Route path="/community" element={<Community />} />
              <Route path="/recents" element={<Recents />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/vendor" element={<VendorDashboard />} />
              <Route path="/vendor/facilities" element={<VendorFacilities />} />
              <Route path="/vendor/facilities/:id" element={<VendorModifyFacility />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/*" element={ <Navigate to="/404" />} />
            </Routes>
          </main>
          <MobileFooterNavigation />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
