

import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import { useParams} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import axios from 'axios';

import showGoogleOneTapLogin from '../scripts/googleonetap'
import { setGlobalState, useGlobalState } from '../scripts/globalstate'

import VendorTypes from '../components/VendorTypes'

const VendorsSkelton = ({show}) => {
  return show && <Grid container spacing={2}> { ['','',''].map(vendor => (
    <Grid item xs={12} sm={6} md={4}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={'100%'} height={220} />
        <Skeleton variant="text" height={40} width={'80%'} />
        <Skeleton variant="text" width={'90%'}  />
        <Skeleton variant="text" width={'90%'}  />
        <Stack spacing={1} direction="row">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton style={{marginLeft:20}} variant="text" width={'50%'} />
        </Stack>
      </Stack>
    </Grid>
  ))} </Grid>
}

export default function VerticalLinearStepper() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userLoggedIn] = useGlobalState('userLoggedIn');
  const [vendors, setVendors] = React.useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  let { category } = useParams();
  console.log('category => ', category);

  const handleGoogleLogin = () => {
    if(!userLoggedIn){
      showGoogleOneTapLogin((googleData)=>{
        setGlobalState('userLoggedIn', googleData.email_verified);
        setGlobalState('userInfo', {
          id: googleData.sub, 
          name: googleData.name, 
          firstname: googleData.given_name, 
          lastname: googleData.family_name, 
          email: googleData.name, 
          picture: googleData.picture, 
          logintype:'GOOGLE'
        });
  
        //console.log('ID', googleData.sub);
        //console.log('Name', googleData.name);
        //console.log('Email', googleData.email);
        //console.log('Picture', googleData.picture);
      });
    }
    else{
      console.log('User already logged in.');
    }
  };

  useEffect(async() => {
    handleGoogleLogin();

    let resp = await axios.post('https://2kvbwnt4n7.execute-api.us-east-1.amazonaws.com/prod', {id:0});
    
    let data = [...resp.data];
    if(category){
      data = data.filter(x=>x.category == category);
    }
    setVendors( data);
    setIsLoading(false);
    //setVendors( Array(10).fill(0).map((x,i)=>({id:1075+i})) );

  }, [category]);

  return (
    <Container sx={{marginTop: isMobile ? 9 : 12, marginBottom:10}}>
    { isLoading && vendors.length == 0 &&
      <VendorsSkelton show={vendors.length == 0} />
    }
    { !isLoading && vendors.length == 0 &&
      <Alert severity="info">
        <AlertTitle>No Results found !</AlertTitle>
        Please change the search and try again.
      </Alert>
    }
    <Fade timeout={500} in={vendors.length > 0}>
    <Grid container spacing={2}>
      { vendors.map(vendor => (
          <Grid item xs={12} sm={6} md={4}>
            <VendorTypes vendor={vendor} />
          </Grid>
        ))
      } 
    </Grid>
    </Fade>
    </Container>
  );
}
