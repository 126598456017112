import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import SwipableGallery from './SwipableGallery';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({vendor}) {
  const [expanded, setExpanded] = React.useState(false);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{minHeight:300, maxWidth: 385 }}>
      <Badge style={{display:'none',position:'relative', zIndex:400, float:'right', right:24 , top:16}} badgeContent={'$299'} color="primary"></Badge> 
      
      { vendor.images.length > 1 &&
      <SwipableGallery images={vendor.images} />
      } 
      { vendor.images.length < 2 &&
      <CardMedia component="img" height="220" image={vendor.images.length > 0 ? vendor.images[0]: ''}  alt={vendor.name} /> 
      }

      <CardContent style={{paddingBottom:0,maxHeight:80}}>
          {/*
         <IconButton style={{padding:5, paddingRight:0,float:'right'}} aria-label="settings">
            <MoreVertIcon />
          </IconButton>
          */}
        <Typography variant="h6" gutterBottom component="div" style={{textTransform:'capitalize'}} color="text.primary">
            {vendor.name.substr(0, 28)}
          </Typography>
        <Typography sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: expanded ? 'none': '-webkit-box',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
          }}  variant="body2" color="text.secondary">
          {vendor.desc.substr(0, 200)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <Typography style={{width:'100%',textAlign:'center'}} variant="body2" color="text.primary">
            {vendor.city}, {vendor.state}
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Type: {vendor.category}<br />
            Community: {vendor.community}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {vendor.desc}
        </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
