//Google One Tap Signin Popup
const clientId = '1096416230246-mnkqsjacngetq883gsihauhbpul57ofc.apps.googleusercontent.com';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export default function showGoogleOneTapLogin(callback){
    let googleScript = document.createElement('script');
    let scriptLoaded = false;
    googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    googleScript.addEventListener('load', (a) => {
      if(scriptLoaded){
        return;
      }

      window.google.accounts.id.initialize({
        client_id: clientId,
        cancel_on_tap_outside: false,
        context: 'signin',
        style: {position:'absolute', top:'80px', right: '10px'},
        callback: (response)=>{
            //console.log(response);
            var googleData = parseJwt(response.credential);
            //console.log(googleData);
            callback(googleData);
            console.log('Google Login Success!');
        },
        native_callback: ((credential, bx) => {
            //console.log('Native Callback');
            //console.log(credential);
            //console.log(bx);
        })
      });

      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // try next provider if OneTap is not displayed or skipped
            console.log('Cannot Show Google Signin Dialog, Because User Cancelled Previosly!');
        }
      });
      
      scriptLoaded = true;
    });
    window.document.head.appendChild(googleScript);
  }

  /*
  google.accounts.id.revoke('user@google.com', done => {
    console.log('consent revoked');
  });
  */