//import * as React from 'react';
import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useNavigate } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function MobileNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if(value===0){
      navigate("/");
    }
    else if(value===1){
      navigate("/browse");
    }
    else if(value===2){
      navigate("/community");
    }
    else if(value===3){
      navigate("/feeds");
    }
    else if(value===4){
      navigate("/settings");
    }
  }, [value]);

  return (
    <>

    <Box sx={{backgroundColor:'#333', width:'100%',color: "#bbb", padding:1, position: "fixed", bottom: 0, display: { xs: 'none', md: 'block' } }}>
      <footer>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{backgroundColor:'#bbb'}} flexItem />}
        spacing={1}
      >
        <small>Copyright 2022</small>
        <small>Powered by PMTeck Inc.</small>
      </Stack>
      </footer>
    </Box>


    <Box sx={{ width: '100%', position: 'fixed', bottom: 0, display: { xs: 'block', md: 'none' } }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Browse" icon={<ManageSearchIcon />} />
        <BottomNavigationAction label="Community" icon={<PeopleIcon />} />
        <BottomNavigationAction label="Feeds" icon={<DynamicFeedIcon />} />
        <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Box>

    </>
  );
}
